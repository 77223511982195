<template>
  <div>
    <Necker></Necker>
    <div class="container py-16 leading-loose">
      <h1 class="text-4xl text-primary font-bold mb-6">著作權聲明</h1>
      <ul class="doc-list">
        <li>
          學習研究社網站jody.com.tw（以下稱「本網站」）上刊載之所有內容（包括但不限於著作、解析、圖片、檔案、資訊、各項統計資料、網站架構、網站畫面的安排、網頁設計），除著作權法規定不得為著作權之標的（如法律、命令、公務員撰擬之公告等--請參考著作權法第9
          條規定）外，均由本網站或其他權利人依法擁有其智慧財產權，並受著作權法保護。
        </li>

        <li>
          任何人不得逕自使用、修改、重製、公開播送、改作、散佈、發行、公開發表、進行還原工程、解編或反向組譯。除符合著作財產權限制或合理使用規範外，若您欲引用或轉載前述網站所有內容，必須依法取得本網站或其他權利人之事前書面同意。
        </li>

        <li>
          <p>前述著作財產權限制或合理使用，舉例說明如下：</p>
          <ul>
            <li>本網站上之資訊，可為個人非營利之目的而重製。</li>
            <li>
              為報導、評論、教學、研究或其他正當目的，在合理範圍內，得引用本網站上之資訊，引用時，並請註明出處。
            </li>
            <li>
              其他合理使用情形，請參考著作權法第四十四條至第六十五條之規定。
            </li>
          </ul>
        </li>
        <li>
          您同意遵守智慧財產權法律之相關規定，如有違反，您同意對本網站負損害賠償責任，並且負擔因此而生之所有費用，其中包括但不限於訴訟費用及律師費用。
        </li>

        <li>
          本聲明中提及著作財產權限制、合理使用之情形如著作權人有疑義者，本網站得隨時限制或暫停該等利用，使用者同意配合處理。
        </li>

        <li>
          除符合著作權法相關規定外，本網站所標示之權利管理電子資訊，未經許可，不得移除或變更，且不得對本網站所採取之防盜拷措施，為任何破解、破壞或規避之行為。
        </li>
      </ul>
      <p class="text-primary text-2xl mt-8 mb-2">著作權之轉載與引用</p>

      <p>
        學習研究社網站jody.com.tw（以下稱「本網站」）希望幫助大家提升學習效率，我們歡迎您轉載與引用本網站內容，但您必須於該複製內容上，完整加入所有相關之著作權、智慧財產權與商標聲明。
      </p>

      <p class="text-primary text-2xl mt-8 mb-2">授權範圍</p>

      <p>
        學習研究社網站jody.com.tw（以下稱「本網站」）中所有內容，包括但不限於著作、解析、圖片、檔案、資訊、各項統計資料、網站架構、網站畫面的安排、網頁設計，均由本網站與其他權利人依法擁有其智慧財產權，請勿自行轉載、重製、散布、公開傳輸、出版、發行、以及修改著作，若欲引用或轉載前述網站內容，請務必與本網站接洽，並依法取得本網站或其他權利人的事前書面同意。
      </p>

      <p class="text-primary text-2xl mt-8 mb-2">資訊正確、完整</p>

      <p>
        我們會盡力確保本站資訊之正確與完整，但請不要假設本網站上的一切資料，隨時都是最新的，您同意自行承擔信賴本網站資料所產生之風險。
      </p>

      <p class="text-primary text-2xl mt-8 mb-2">兒童及未成年者之保護</p>

      <p>
        為確保兒童及青少年使用網路的安全，並避免隱私權受到侵犯，當未成年者瀏覽網際網路時，家長（或監護人）應盡到監護義務。
      </p>

      <p class="text-primary text-2xl mt-8 mb-2">連結至其他網站</p>

      <p>
        本網站提供之連結可能會使您離開我們的網站或系統，本站基於善意提供連結，但不負責連結網站（第三方網站）日後的任何變更。我們建議您仔細閱讀所有您造訪網站之法律與隱私權聲明。
      </p>

      <p class="text-primary text-2xl mt-8 mb-2">保證與免責聲明</p>

      <p>
        您將自行承擔使用本網站之一切風險。我們不保證本網站不會出現中斷或故障等現象，也無法保證本網站不會遭病毒或其他有害物件侵害，或許將造成您使用上的不便、資料喪失、錯誤、遭人篡改或其他損失等情形。
      </p>
    </div>
  </div>
</template>

<script setup>
import Necker from '@/views/_necker/Index.vue';
import { provide, ref } from 'vue';

provide('page', {
  breadcrumbs: ref(['著作權與免責聲明']),
  title: ref('著作權與免責聲明'),
  pcBg: ref('banner04.png'),
  mobileBg: ref('banner04_m.png'),
});
</script>

<style lang="sass" scoped></style>
