<template>
  <div>
    <Necker></Necker>
    <div class="container py-16 leading-loose">
      <h1 class="text-4xl text-primary font-bold mb-6">使用服務條款</h1>
      <ol class="doc-list">
        <li>
          Privacy
          <p>
            jody.com.tw 係依據本服務條款提供jody.com.tw服務
            (以下簡稱「本服務」)。當您使用本服務時，即表示您已閱讀、瞭解並同意接受本服務條款之所有內容。當您使用
            jody.com.tw
            的特定服務時，可能會依據該特定服務之性質，而須遵守jody.com.tw
            所另行公告之服務條款或相關規定時，此另行公告之服務條款或相關規定亦均併入屬於本服務條款之一部分。如依法或其他相關規定須為通知時，jody.com.tw
            得以電子郵件、一般信件、簡訊、多媒體簡訊、文字訊息、張貼於本服務網頁，或其他現在或未來合理之方式通知您，但若您違反本服務條款，您將不會收到前述通知。當您經由授權的方式存取本服務，而同意本服務條款時，您即同意
            jody.com.tw 所為之任何及所有給您的通知，都視為送達。
          </p>
        </li>
        <li>
          <p>
            jody.com.tw 有權修改或變更本條款之內容
            (含標題名稱)。您於任何修改或變更後仍繼續使用本服務時，將視為您同意接受修改或變更。
          </p>
        </li>
        <li>
          <p>
            若您為未滿二十歲，除應符合上述規定外，並應於您的家長 (或監護人)
            閱讀、瞭解並同意本服務條款之所有內容及其後修改變更後，方得使用或繼續使用本服務。當您使用或繼續使用本服務時，即視為您的家長
            (或監護人)
            已閱讀、瞭解並同意接受本服務條款之所有內容及其後修改變更。
          </p>
        </li>
        <li>
          <p>為了能使用本服務，您需同意以下事項：</p>
          <ul>
            <li>
              <p>依本服務註冊表之提示提供您本人正確、最新及完整的資料。</p>
            </li>
            <li>
              <p>
                維持並更新您個人資料，確保其為正確、最新及完整。若您提供任何錯誤、不實或不完整的資料，本服務有權暫停或終止您的帳號，並拒絕您使用本服務之全部或一部。
              </p>
            </li>
            <li>
              <p>
                您的密碼或帳號遭到盜用或有其他任何安全問題發生時，您需立即通知
                jody.com.tw。
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            您承諾絕不為任何非法目的或以任何非法方式使用本服務，並承諾遵守中華民國相關法規。您若係中華民國以外之使用者，並同意遵守所屬國家或地域之法令。
          </p>
          <ul>
            <li>
              <p>
                您同意並保證不得利用本服務從事侵害他人權益或違法之行為，例如：<br />上載、張貼、公布或傳送任何誹謗、侮辱、具威脅性、攻擊性、不雅、猥褻、不實、違反公共秩序或善良風俗或其他不法之文字、圖片或任何形式的檔案於本服務上。
              </p>
            </li>
            <li>
              <p>
                侵害他人名譽、隱私權、營業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利。
              </p>
            </li>
            <li>
              <p>違反依法律或契約所應負之保密義務。</p>
            </li>
            <li>
              <p>冒用他人名義使用本服務。</p>
            </li>
            <li>
              <p>
                上載、張貼、傳輸或散佈任何含有電腦病毒或任何對電腦軟、硬體產生中斷、破壞或限制功能之程式碼之資料。
              </p>
            </li>
            <li>
              <p>從事不法交易行為或張貼虛假不實、引人犯罪之訊息。</p>
            </li>
            <li>
              <p>提供賭博資訊或以任何方式引誘他人參與賭博。</p>
            </li>
            <li>
              <p>濫發廣告郵件、垃圾郵件、連鎖信、違法之多層次傳銷訊息等。</p>
            </li>
            <li>
              <p>偽造訊息來源或以任何方式干擾傳輸來源之認定。</p>
            </li>
            <li>
              <p>
                追蹤他人或其他干擾他人或為前述目前蒐集或儲存他人之個人資訊。
              </p>
            </li>
            <li>
              <p>
                其他 jody.com.tw
                有正當理由認為不適當之行為。(如：開人頭帳號惡意獲取鑽石兌換現金)
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            本服務可能會出現中斷或故障等現象，將造成您使用上的不便、資料喪失、錯誤、遭人篡改或其他經濟上損失等情形，jody.com.tw
            對於您因使用 (或無法使用)
            本服務而造成的損害，除故意或重大過失外，不負任何賠償責任。
          </p>
        </li>
        <li>
          <p>
            jody.com.tw
            對於您使用本服務或經由本服務連結之其他網站而取得之資訊，不擔保其為完全正確無誤。jody.com.tw
            對於本服務所提供之資訊或建議有權隨時修改或刪除。基於尊重內容提供者之智慧財產權，本服務對其所提供之內容並不做實質之審查或修改，對該等內容之正確真偽亦不負任何責任，您若認為某些內容涉及侵權或有所不實，請逕向該內容提供者反應意見。
          </p>
        </li>
        <li>
          <p>您明確了解並同意：jody.com.tw不提供任何明示或默示的擔保，例如：</p>
          <ul>
            <li>
              <p>本服務及軟體將符合您的需求，</p>
            </li>
            <li>
              <p>本服務及軟體不受干擾、及時提供、安全可靠或無錯誤，</p>
            </li>
            <li>
              <p>由本服務及軟體之使用而取得之結果為正確或可靠，</p>
            </li>
            <li>
              <p>
                您經由本服務購買或取得之任何產品、服務、資訊或其他資料將符合您的期望，
              </p>
            </li>
            <li>
              <p>
                任何軟體中之錯誤將被修正。
                是否經由本服務及軟體之使用下載或取得任何資料應由您自行考量且自負風險，並拋棄因前開任何資料之下載而導致您電腦系統、網路存取、下載或播放設備之任何損壞或資料流失，對
                jody.com.tw 提出任何請求或採取法律行動，您應自負完全責任。
              </p>
            </li>
            <li>
              <p>
                本服務中瀏覽到的所有廣告內容、文字與圖片之說明、展示樣品或其他銷售資訊，均由各該廣告商、產品與服務的供應商所設計與提出。本服務僅接受委託予以刊登，不對前述廣告負擔保責任。
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            jody.com.tw
            其子公司、關係企業、經理人、受僱人、代理人、合夥人及授權人亦無須為您任何直接、間接、附隨、特別、衍生、懲罰性的損害、所生利潤、商譽、使用、資料之損害或其他無形損失負責。由會員公開張貼或私下傳送的資訊、資料、文字、軟體、音樂、音訊、照片、圖形、視訊、信息或其他資料（以下簡稱「會員內容」），均由「會員內容」提供者自負責任，對於您可能會接觸到令人不快、不適當、令人厭惡之「會員內容」例如任何錯誤或遺漏，以及經由本服務張貼、發送電子郵件或傳送而衍生之任何損失或損害，jody.com.tw
            不保證其正確性、完整性或品質， 但 jody.com.tw
            有權（但無義務）依其自行之考量，拒絕或移除經由本服務提供之任何「會員內容」，得將有違反本服務條款或法令之虞、或令人厭惡之任何「會員內容」加以移除。
          </p>
        </li>
        <li>
          <p>
            您使用任何「會員內容」時，就前開「會員內容」之正確性、完整性或實用性之情形，您同意必須自行加以評估並承擔所有風險。<br />您保證您提供之「會員內容」供
            jody.com.tw
            使用、修改、重製、公開播送、改作、散布、發行、公開發表、公開傳輸、公開上映、翻譯、轉授權該等資料，不致侵害任何第三人之權利，否則除應對
            jody.com.tw
            負損害賠償責任外，因您經由本服務提供、張貼或傳送「會員內容」、使用本服務、與本服務連線、違反本服務條款、或侵害其他人任何權利所衍生或導致任何第三人為請求或主張時，您應使
            jody.com.tw
            及其子公司、關係企業、經理人、代理人、受僱人、合夥人及授權人免於任何損害。<br />您了解並同意，jody.com.tw
            依據法律的要求，或基於以下目的之合理必要範圍內，認定必須將您的帳戶資訊或「會員內容」加以保存或揭露予政府機關、司法警察或未成年人之監護人時，得加以保存及揭露：(a)遵守法令或政府機關之要求，(b)為提供本服務所必須者，(c)為防止他人權益之重大危害而有必要者，(d)為免除使用者及公眾之生命、身體、自由、權利、財產上之急迫危險者。<br />您了解本服務及本服務所包含之軟體，可能含有使數位資料得以被保護之安全元件，使用該等資料必須依
            jody.com.tw
            或提供該內容予本服務之內容提供者所定的使用規則。您不得試圖破壞或規避任何內含於本服務之使用規則。任何未經合法授權之重製、發行、散布或公開展示本服務所提供之資料之全部或一部，均被嚴格禁止。您同意不對
            jody.com.tw 任何服務之使用或存取，進行重製、拷貝、出售
            、交易、轉售或作任何商業目的之使用。
          </p>
        </li>
        <li>
          <p>
            您同意 jody.com.tw
            於任何時點，不經通知隨時修改或暫時或永久停止繼續提供本服務（或其任一部分）的權利，且對於您或是任何第三方任何修改、暫停或停止繼續提供本服務不負任何責任。
          </p>
        </li>
        <li>
          <p>
            您同意 jody.com.tw
            得依其判斷因任何理由，包含但不限於一定期間未使用、法院或政府機關命令、本服務無法繼續或服務內容實質變更、無法預期之技術或安全因素或問題、您所為詐欺或違法行為、未依約支付費用，或其他認為您已經違反本服務條款的明文規定及精神，而終止或限制您使用帳號（或其任何部分）或本服務之使用，並將本服務內任何「會員內容」加以移除並刪除。您並同意
            jody.com.tw
            亦得依其自行之考量，於通知或未通知之情形下，隨時終止或限制您使用本服務或其任何部分。您承認並同意前開終止或限制，jody.com.tw
            得立即關閉、刪除或限制存取您的帳號及您帳號中全部或部分相關資料及檔案，及停止本服務全部或部分之使用。此外，您同意若本服務之使用被終止或限制時，jody.com.tw
            對您或任何第三人均不承擔責任。
          </p>
        </li>
        <li>
          <p>
            本服務條款之解釋與適用，以及與本服務條款有關的爭議，除法律另有規定者外，均應依照中華民國法律予以處理，並以台灣台中地方法院為第一審管轄法院。
          </p>
        </li>
      </ol>
      <p>
        jody.com.tw
        若未行使或執行本服務條款任何權利或規定時，不構成前開權利或規定之棄權。若任何本服務條款規定，經有管轄權之法院認定無效，當事人仍同意法院應努力使當事人於前開規定所表達之真意生效，且本服務條款之其他規定仍應完全有效。&nbsp;<br />您同意您的本服務帳號係不可轉讓，且您帳號或帳號中內容之任何權利，於您死亡時即立刻終止。於接獲您的死亡證明文件時，您的帳號將被終止。
      </p>
    </div>
  </div>
</template>

<script setup>
import Necker from '@/views/_necker/Index.vue';
import { provide, ref } from 'vue';

provide('page', {
  breadcrumbs: ref(['使用服務條款']),
  title: ref('使用服務條款'),
  pcBg: ref('banner04.png'),
  mobileBg: ref('banner04_m.png'),
});
</script>

<style lang="sass" scoped></style>
